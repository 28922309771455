///var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/website/my-app/src/component/Footer.js

import React from 'react';
import '../style/Footer.scss';

const Footer = () => {
  return (
    <footer>
      <p>&copy; COPYRIGHT © VIP-MATCHING 2024 ALL RIGHTS RESERVED | DEVELOP. BY RAMPAGESOFT </p>
    </footer>
  );
}

export default Footer;
