///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/Step3PackageSelection.js

import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import '../style/Registerstep3.scss'; // Import your custom styles

const Step3PackageSelection = ({ incomeClassId, onComplete, onBack }) => {
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    // Fetch packages based on selected income class
    // Replace with actual API call
    const fetchedPackages = [
      {
        id: 1,
        name: 'Basic',
        price: 10,
        features: 'Basic Dating Service',
        isHighlighted: false,
      },
      {
        id: 2,
        name: 'Basic+',
        price: 15,
        features: 'Enhanced Dating Service with Additional Features',
        isHighlighted: false,
      },
      {
        id: 3,
        name: 'Premium',
        price: 20,
        features: 'Premium Matchmaking Service',
        isHighlighted: false,
      }, {
        id: 4,
        name: 'Premium+',
        price: 25,
        features: 'Extended Dating Service with High-profile Matching',
        isHighlighted: false,
      }, {
        id: 5,
        name: 'Exclusive',
        price: 30,
        features: 'Exclusive Dating and Consulting Services',
        isHighlighted: true,
      }, {
        id: 6,
        name: 'Luxurious',
        price: 35,
        features: 'Luxurious Dating Service with Elite Profiles',
        isHighlighted: false,
      }, {
        id: 7,
        name: 'Full Service',
        price: 40,
        features: 'Full-service Package Including Personal Consultations and Tailored Matches',
        isHighlighted: false,
      }, {
        id: 8,
        name: 'Ultra Luxurious',
        price: 45,
        features: 'Ultra-luxurious Service Including Celebrities and High Net Worth Individuals',
        isHighlighted: false,
      }, {
        id: 9,
        name: 'Top Tier',
        price: 50,
        features: 'Top-tier Service with Global Elite, Including Special Events Access',
        isHighlighted: false,
      },
    ];
    setPackages(fetchedPackages);
  }, [incomeClassId]);

  const handlePackageSelect = (pkg) => {
    if (selectedPackage && selectedPackage.id === pkg.id) {
      setSelectedPackage(null);
    } else {
      setSelectedPackage(pkg);
    }
  };

  const handleComplete = () => {
    if (selectedPackage) {
        console.log("Complete button clicked"); // Add this line
        onComplete(selectedPackage);
    }
};


  return (
    <div className="container mt-5 package-selection-step3">
      <div className="row justify-content-center">
        {packages.map((pkg) => (
          <div key={pkg.id} className="col-md-4 mb-4">
            <Card
              className={`package-card ${selectedPackage && selectedPackage.id === pkg.id ? 'selected' : ''} ${pkg.isHighlighted ? 'highlighted' : ''}`}
              onClick={() => handlePackageSelect(pkg)}
            >
              <h3>{pkg.name}</h3>
              <p className="price">${pkg.price}</p>
              <h5>{pkg.features}</h5>

              <Button className='buttonstep3' label="Choose Plans" onClick={() => handlePackageSelect(pkg)} />
            </Card>
          </div>
        ))}
      </div>
      <div className="mt-3 text-center">
        <Button label="Back" onClick={onBack} className="mr-2" />
        <Button label="Complete" onClick={handleComplete} disabled={!selectedPackage} />
      </div>
    </div>
  );
};

export default Step3PackageSelection;
