// /var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/utils/CurrentUserData.js


const fetchCurrentUserData = async () => {
    try {
        const currentUser = localStorage.getItem('user'); // Retrieve token from local storage
        const user = currentUser ? JSON.parse(currentUser) : null;

        if (!user.token) {
            throw new Error('No token found');
        }

        console.log("Token:", user.token);
        console.log("user_id:", user.user);

        const response = await fetch(`https://py-maracar.afaa.website/matching/api_env_2/user?user_id=${user.user}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,  // Include Bearer token in the request
            }
        });

        if (!response.ok) {
            console.log('API Response:', response.status, response.statusText);
            throw new Error('Failed to fetch current user data');
        }

        const userData = await response.json();
        console.log("Parsed user data:", userData);

        // Adjust to handle the array of objects returned by the API
        const profile = userData[0]?.profile_data[0];  // Ensure to access the first object and its profile_data array
        const packageDetails = userData[0]?.user_packages ? userData[0].user_packages[0] : null;

        if (!profile) {
            throw new Error('Profile data is missing');
        }


        // Ensure the formattedUser object matches the structure of the API response
        const formattedUser = {
            id: profile.user_id,
            name: profile.name,
            age: profile.age,
            location: profile.location || "New York",
            occupation: profile.occupation || 1,
            income: parseFloat(profile.income),
            net_worth: parseFloat(profile.net_worth),
            package: packageDetails ? packageDetails.package : "Basic",
            profile_image: profile.profile_image || "https://afaa.website/s/995abb.svg",
            gender: profile.gender || "Male", // Assuming gender comes from profile
            nationality: profile.nationality || 1, // Assuming nationality comes from profile
            matching_gender: profile.matching_gender || 1, // Assuming matching_gender comes from profile
            matching_nationality: profile.matching_nationality || 1 , // Assuming matching_nationality comes from profile
            matching_occupation: profile.matching_occupation || 1 , // Assuming matching_occupation comes from profile
            car_brand: profile.car_brand || 1,
            income_class: profile.income_class_id
        };

        console.log("Formatted User Data:", formattedUser);

        return formattedUser;  // Return the formatted user data
    } catch (error) {
        console.error('Error fetching current user:', error);
        return null;
    }
};


export default fetchCurrentUserData;
