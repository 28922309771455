// /var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/ProfileDetails.js

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from 'primereact/card';
import '../style/Profile.scss'; // Import the SCSS file

const ProfileDetails = ({ profile, editMode, setEditMode, onSave }) => {

  // Function to parse the date string
  const parseDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [updatedProfile, setUpdatedProfile] = useState({
    ...profile,
    birthday: parseDate(profile.birthday), // Format the birthday correctly
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProfile({ ...updatedProfile, [name]: value });
  };

  const handleSave = () => {
    onSave(updatedProfile);
  };

  return (
    <div className="container profile-container mt-5">
      <Card title="Profile" className="profile-card">
        {!editMode ? (
          <div className="profile-details">
            <p><strong>Email:</strong> {profile.email}</p>
            <p><strong>First Name:</strong> {profile.first_name}</p>
            <p><strong>Last Name:</strong> {profile.sur_name}</p>
            <p><strong>Birthday:</strong> {new Date(profile.birthday).toLocaleDateString()}</p>
            <p><strong>Contact:</strong> {profile.contact}</p>
            <p><strong>Telephone:</strong> {profile.telephone}</p>
            <p><strong>Address:</strong> {`${profile.contact}`}</p>
            <button className="btn btn-primary" onClick={() => setEditMode(true)}>Edit Profile</button>
          </div>
        ) : (
          <div className="profile-details">
            <form>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={updatedProfile.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>First Name:</label>
                <input
                  type="text"
                  name="first_name"
                  className="form-control"
                  value={updatedProfile.first_name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Last Name:</label>
                <input
                  type="text"
                  name="sur_name"
                  className="form-control"
                  value={updatedProfile.sur_name}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>Birthday:</label>
                <input
                  type="date"
                  name="birthday"
                  className="form-control"
                  value={updatedProfile.birthday}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>Contact:</label>
                <input
                  type="text"
                  name="contact"
                  className="form-control"
                  value={updatedProfile.contact}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Telephone:</label>
                <input
                  type="tel"
                  name="telephone"
                  className="form-control"
                  value={updatedProfile.telephone}
                  onChange={handleChange}
                />
              </div>



              <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
              <button type="button" className="btn btn-light" onClick={() => setEditMode(false)}>Cancel</button>
            </form>
          </div>
        )}
      </Card>
    </div>
  );
};

export default ProfileDetails;
