// /var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/page/login.js
import React, { useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../style/LoginPage.scss';
import { API_URLS } from '../api/apiConfig';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [showPassword, setShowPassword] = useState(false);


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(API_URLS.LOGIN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();
      localStorage.setItem('user', JSON.stringify(data));

      toast.current.show({ severity: 'success', summary: 'Login Successful', detail: 'Redirecting to profile...' });
      setTimeout(() => navigate('/profile'), 2000); // Redirect after 2 seconds
    } catch (error) {
      console.error('Error:', error);
      toast.current.show({ severity: 'error', summary: 'Login Failed', detail: 'Please check your credentials.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container login-container">
      <Toast ref={toast} />
      {loading && <ProgressSpinner />}
      <div className="row justify-content-center align-items-center min-vh-100">
        <div className="col-lg-6 d-none d-lg-block">
          <img src="https://afaa.website/s/4272b5.webp" alt="Login Illustration" className="img-fluid imgdating" />
        </div>
        <div className="col-lg-6">
          <div className="card login-right">
            <div className="card-body">
              <div className="login-header text-left">
                <h3>Login</h3>
              </div>
              <form onSubmit={handleLogin}>
                <div className="mb-3 form-focus">
                  <input
                    type="email"
                    className="form-control floating"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-3">
                <input
    type={showPassword ? "text" : "password"}
    className="form-control"
    id="password"
    placeholder="Password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    required
  />
  <FontAwesomeIcon
    icon={showPassword ? faEyeSlash : faEye}
    className="position-absolute end-0 top-50 translate-middle-y me-2"
    style={{ cursor: 'pointer' }}
    onClick={() => setShowPassword(!showPassword)}
  />
                </div>

                <div className="mb-3 text-end">
                  <a href="/forgot-password" className="text-decoration-none">Forgot Password?</a>
                </div>
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary btn-lg" disabled={loading}>Login</button>
                </div>
              </form>
              <div className="text-center mt-3">
                Don't have an account? <a href="/register" className="text-decoration-none">Register</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
