// /var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import AppNavbar from './component/Navbar';
import Home from './page/home';
import Register from './page/register';
import Login from './page/login';
import ForgotPassword from './page/forgot-password';
import Footer from './component/Footer';
import Profile from './page/Profile'; // Import the new Profile page
import Matching from './component/Matching'; // Import the new Matching page
import FindMatch from './page/FindMatch'; // Import the FindMatch page


function App() {
  return (

    <Router basename="/">
      <div className="App">
        <AppNavbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/register" element={<Register />} /> {/* Use the Register component */}
          <Route path="/login" element={<Login />} /> {/* Use the Register component */}
          <Route path="/forgot-password" element={<ForgotPassword />} /> {/* Use the Register component */}
          <Route path="/profile" element={<Profile />} /> {/* Add the Profile route */}
          <Route path="/matching" element={<Matching />} />
          <Route path="/find-match" element={<FindMatch />} /> 
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
