import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import '../style/Sidebar.scss';
import { API_URLS } from '../api/apiConfig';

// Function to create authorization headers
const createAuthHeaders = (token) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  };
};

const Sidebar = ({ setActiveComponent }) => {
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) : null;

  const defaultImage = 'https://afaa.website/s/3d0891.svg';

  const [profileImage, setProfileImage] = useState(user?.image || defaultImage);
  const [previewImage, setPreviewImage] = useState(null);
  const [uploading, setUploading] = useState(false); // State to track uploading status

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${API_URLS.FETCH_PROFILE}?user_ID=${user.user}`,
          createAuthHeaders(user.token)
        );
        const apiImage = response.data.image || 'https://afaa.website/s/995abb.svg';
        setProfileImage(apiImage);
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        setProfileImage('https://afaa.website/s/995abb.svg'); // fallback default image
      }
    };

    fetchUserData();
  }, [user.user, user.token]);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    setPreviewImage(URL.createObjectURL(file)); // Generate preview image
    setUploading(true); // Start spinner when uploading begins

    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_ID', user.user);

    try {
      const uploadResponse = await axios.post(API_URLS.UPLOAD_PROFILE_IMAGE, formData);

      if (uploadResponse.status === 200) {
        console.log('Upload Response Data:', uploadResponse.data);

        const fileNames = uploadResponse.data.fileNames;
        const originalFileURLs = uploadResponse.data.originalFileURLs;
        const resizedFileURLs = uploadResponse.data.resizedFileURLs;

        if (!fileNames || !originalFileURLs || !resizedFileURLs) {
          console.error('Missing required data in the upload response');
          setUploading(false); // Stop spinner if upload fails
          return;
        }

        const uuid = fileNames[0].split('.')[0];

        const data = [
          {
            user_ID: user.user,
            tabledataID: 78,
            uuid: uuid,
            fileNames: fileNames,
            originalFileURLs: originalFileURLs,
            resizedFileURLs: resizedFileURLs,
          }
        ];

        console.log('Data to send to IMAGE_UPLOAD API:', data);

        const imgUpdateResponse = await axios.post(API_URLS.IMAGE_UPLOAD, data, createAuthHeaders(user.token));

        if (imgUpdateResponse.status === 200) {
          const imageId = imgUpdateResponse.data.image_id;
          const originalFileURL = originalFileURLs[0];

          const profileUpdateResponse = await axios.post(
            `${API_URLS.EDIT_PROFILE}?user_ID=${API_URLS.getUserID()}`,
            { image: imageId },
            createAuthHeaders(user.token)
          ).catch((error) => {
            if (error.response) {
              const errorMessage = error.response.data.message;
              if (errorMessage !== 'Missing required data') {
                console.error('Error uploading image:', error.response.data);
              }
              return null;
            } else if (error.request) {
              console.error('Error with request:', error.request);
              throw error;
            } else {
              console.error('General Error:', error.message);
              throw error;
            }
          });

          if (profileUpdateResponse && profileUpdateResponse.status === 200) {
            setProfileImage(originalFileURL);
            setPreviewImage(null);

            const updatedUser = { ...user, image: originalFileURL };
            localStorage.setItem('user', JSON.stringify(updatedUser));
          } else if (profileUpdateResponse === null) {
            console.log('Handled error: No further action needed.');
          } else {
            console.error('Failed to update profile image');
          }
        }
      } else {
        console.error('Failed to upload image');
      }
    } catch (error) {
      console.error('Error uploading image:', error.message);
    } finally {
      setUploading(false); // Stop spinner when upload is complete or fails
    }
  }, [user]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/jpeg, image/png, image/webp' });

  return (
    <div className="sidebar">
      <div className="profile-image" {...getRootProps()} style={{ position: 'relative', display: 'inline-block' }}>
        <input {...getInputProps()} />
        {uploading ? (
          <div className="spinner">Loading...</div> // Display a spinner during upload
        ) : (
          <img src={previewImage || profileImage} alt="Profile" className="img-profile" />
        )}
        <div style={{
          position: 'absolute',
          bottom: '5px',
          right: '10px',
          backgroundColor: 'rgb(169 0 0 / 43%)',
          borderRadius: '50%',
          padding: '5px',
          cursor: 'pointer',
          color: 'white'
        }}>
          <i className="fa-solid fa-cloud-arrow-up"></i>
        </div>
      </div>
      <nav className="menu">
        <button onClick={() => setActiveComponent('profile')}>PROFILE</button>
        <button onClick={() => setActiveComponent('matching')}>MATCHING</button>
        <button onClick={() => setActiveComponent('subscription')}>SUBSCRIPTION</button>
        <button onClick={() => setActiveComponent('billing')}>BILLING</button>
      </nav>
    </div>
  );
};

export default Sidebar;
