///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/Subscription.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


const Subscription = () => {
  return (
    <div className="container profile-container mt-5">
      <div className="profile-card">
        <h2>Subscription Page</h2>
        {/* Add content for subscription page */}
      </div>
    </div>
  );
};

export default Subscription;
