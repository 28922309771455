// /var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/BannerForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import '../style/Banner.scss';

const BannerForm = () => {
    const [gender, setGender] = useState(null);
    const [seeking, setSeeking] = useState(null);
    const [ageFrom, setAgeFrom] = useState(null);
    const [ageTo, setAgeTo] = useState(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const genderOptions = [
        { label: 'Select Gender', value: null },
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Others', value: 'Others' },
    ];

    const ageOptions = Array.from({ length: 13 }, (v, k) => ({ label: 18 + k, value: 18 + k }));

    const handleSearch = () => {
        setLoading(true);

        // Navigate to the FindMatch page with search parameters
        navigate(`/find-match?gender=${gender}&seeking=${seeking}&ageFrom=${ageFrom}&ageTo=${ageTo}`);

        setLoading(false); // Stop loading
    };

    return (
        <div className="banner-container">
            <form action="/" className="banner-form">
                <div className="form-row">
                    <label>I am a </label>
                    <Dropdown value={gender} options={genderOptions} onChange={(e) => setGender(e.value)} placeholder="Select Gender" />
                </div>
                <div className="form-row">
                    <label>Looking for</label>
                    <Dropdown value={seeking} options={genderOptions} onChange={(e) => setSeeking(e.value)} placeholder="Select Gender" />
                </div>
                <div className="form-row age-row">
                    <label>From</label>
                    <Dropdown value={ageFrom} options={ageOptions} onChange={(e) => setAgeFrom(e.value)} placeholder="Age from" />
                    <label>To</label>
                    <Dropdown value={ageTo} options={ageOptions} onChange={(e) => setAgeTo(e.value)} placeholder="Age to" />
                </div>
                <Button label="Search" onClick={handleSearch} loading={loading} />
            </form>
        </div>
    );
};

export default BannerForm;
