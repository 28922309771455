// /var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/api/apiConfig.js
const API_BASE_URL = 'https://py-maracar.afaa.website';
const UPLOAD_API_BASE_URL = 'https://go.afaa.website';

export const API_URLS = {

  getUserID: () => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser).user : null;
  },
  
  LOGIN: `${API_BASE_URL}/login/api_env_2/check`,
  LOGOUT: `${API_BASE_URL}/logout/api/checkout`,
  FETCH_PROFILE: `${API_BASE_URL}/register/api_env_2/user`,
  EDIT_PROFILE: `${API_BASE_URL}/register/api_env_2/edit`,
  UPLOAD_PROFILE_IMAGE: `${UPLOAD_API_BASE_URL}/aachat/api/upload`,
  IMAGE_UPLOAD: `${API_BASE_URL}/img/api/upload`,
};
