import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/LoginPage.scss';

const ForgotPassword = () => {
  return (
    <div className="container login-container">
      <div className="row justify-content-center align-items-center min-vh-100">
        <div className="col-lg-6 d-none d-lg-block">
          <img src="https://afaa.website/s/e49784.webp" alt="Login Illustration" className="img-fluid imgdating" />
        </div>
        <div className="col-lg-6">
          <div className="card login-right">
            <div className="card-body">
              <div className="login-header text-left">
                <h3>Forgot Password?</h3>
                <p className="small text-muted">Enter your email to get a password reset link</p>
              </div>
              <form>
                <div className="mb-3 form-focus">
                  <input type="email" className="form-control floating" id="email" placeholder="Email" />
                </div>

                <div className="mb-3 text-end">
                  <a href="/login" className="text-decoration-none">Remember your password?</a>
                </div>
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary btn-lg">Reset Password</button>
                </div>


              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
