// /var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/Matching.js
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import '../style/Matching.scss'; // Import the SCSS file
import getNextMatch from '../utils/getNextMatch';
import { Button } from 'primereact/button';
import fetchCurrentUserData from '../utils/CurrentUserData';

const Matching = () => {
  const [data, setData] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showNoMatchAlert, setShowNoMatchAlert] = useState(false);
  const [viewedMatches, setViewedMatches] = useState(new Set());
  const [initialLoading, setInitialLoading] = useState(true);
  const [nextLoading, setNextLoading] = useState(false);
  const [occupationOptions, setOccupationOptions] = useState([]);

  useEffect(() => {
    const loadOccupationOptions = async () => {
      const response = await fetch('/data/occupationOptions.json'); // Adjust the path if necessary
      const options = await response.json();
      setOccupationOptions(options);
    };
  
    loadOccupationOptions();
  }, []);

  const getOccupationLabel = (occupationId) => {
    const occupation = occupationOptions.find(option => option.id === occupationId);
    return occupation ? occupation.label : 'Unknown';
  };

  useEffect(() => {
    const loadUserData = async () => {
      const userData = await fetchCurrentUserData();
      if (userData) {
        setCurrentUser(userData);
      }
      setInitialLoading(false);
    };
    
    loadUserData();
  }, []);

  useEffect(() => {
    if (currentUser) {
      console.log("Fetching matches for currentUser:", currentUser);
      const fetchMatches = async () => {
        const matches = await getNextMatch(currentUser);
        console.log("Matches found:", matches);
        setData(matches || []);
      };
      fetchMatches();
    }
  }, [currentUser]);

  const handleNext = () => {
    setNextLoading(true);
    setTimeout(() => {
      const matches = getNextMatch(currentUser, viewedMatches);
      console.log("Next matches:", matches);
      if (!matches || matches.length === 0) {
        setShowNoMatchAlert(true);
        setNextLoading(false);
      } else {
        const newIndex = (currentIndex + 1) % matches.length;
        if (matches[newIndex]) {
          setViewedMatches(prev => new Set(prev).add(matches[newIndex].user_id));
          setData(matches);
          setCurrentIndex(newIndex);
        }
        setNextLoading(false);
      }
    }, 1000);
  };

  const handlePrevious = () => {
    setNextLoading(true);
    setTimeout(() => {
      const matches = getNextMatch(currentUser, viewedMatches);
      console.log("Previous matches:", matches);
      if (!matches || matches.length === 0) {
        setShowNoMatchAlert(true);
        setNextLoading(false);
      } else {
        const newIndex = (currentIndex - 1 + matches.length) % matches.length;
        if (matches[newIndex]) {
          setViewedMatches(prev => new Set(prev).add(matches[newIndex].user_id));
          setData(matches);
          setCurrentIndex(newIndex);
        }
        setNextLoading(false);
      }
    }, 1000);
  };

  if (initialLoading || !data) {
    return <ProgressSpinner />;
  }

  if (showNoMatchAlert || data.length === 0) {
    return (
      <div className="no-match-alert">
        <p>No matches found</p>
        <button onClick={() => setShowNoMatchAlert(false)} className="btn btn-primary">OK</button>
      </div>
    );
  }

  const currentMatch = data[currentIndex];

  if (!currentMatch) {
    return (
      <div className="no-match-alert">
        <p>No current match found.</p>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <h2>Matching Page</h2>
        <div className="profile-container mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="profile-card current-user">
                <div className="match-item">
                  <img src={currentUser.profile_image} alt={`${currentUser.name} profile`} className="profile-image" />
                  <p><strong>Name:</strong> {currentUser.name}</p>
                  <p><strong>Age:</strong> {currentUser.age}</p>
                  <p><strong>Location:</strong> {currentUser.location}</p>
                  <p><strong>Occupation:</strong> {getOccupationLabel(currentUser.occupation)}</p>
                  <p><strong>Income:</strong> ${currentUser.income.toLocaleString()}</p>
                  <p><strong>Net Worth:</strong> ${currentUser.net_worth.toLocaleString()}</p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="profile-card matched-user">
                <Button icon="pi pi-angle-left" className="nav-icon" onClick={handlePrevious} />
                <div className="match-item">
                  {nextLoading ? (
                    <div className="loading-overlay">
                      <ProgressSpinner />
                      <Skeleton width="150px" height="150px" className="skeleton-circle" />
                      <Skeleton width="70%" height="20px" className="skeleton-text" />
                      <Skeleton width="50%" height="20px" className="skeleton-text" />
                      <Skeleton width="80%" height="20px" className="skeleton-text" />
                      <Skeleton width="80%" height="20px" className="skeleton-text" />
                      <Skeleton width="60%" height="20px" className="skeleton-text" />
                      <Skeleton width="60%" height="20px" className="skeleton-text" />
                    </div>
                  ) : (
                    <>
                      <img src={currentMatch.profile_image} alt={`${currentMatch.name} profile`} className="profile-image" />
                      <p><strong>Name:</strong> {currentMatch.name}</p>
                      <p><strong>Age:</strong> {currentMatch.age}</p>
                      <p><strong>Location:</strong> {currentMatch.location}</p>
                      <p><strong>Occupation:</strong> {getOccupationLabel(currentMatch.occupation)}</p>
                      <p><strong>Income:</strong> ${currentMatch.income.toLocaleString()}</p>
                      <p><strong>Net Worth:</strong> ${currentMatch.net_worth.toLocaleString()}</p>
                    </>
                  )}
                </div>
                <Button icon="pi pi-angle-right" className="nav-icon" onClick={handleNext} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Matching;
