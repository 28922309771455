///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/SlideMember.js

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProgressSpinner } from 'primereact/progressspinner'; // Import ProgressSpinner
import { Skeleton } from 'primereact/skeleton'; // Import Skeleton

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import required modules from Swiper
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCoins } from '@fortawesome/free-solid-svg-icons';
import '../style/SlideMember.scss';

const DoctorCard = ({ doctor }) => (
  <div className="card h-100 d-flex flex-column equal-height mb-2">
    <img src={doctor.image} className="card-img-top centered-image" alt={doctor.name} />
    <div className="card-body flex-grow-1 d-flex flex-column justify-content-between">
      <h5 className="card-title">{doctor.name}</h5>
      <p className="card-text">{doctor.speciality}</p>
      <div className="d-flex justify-content-start align-items-center mb-2">
        <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
        <span>{doctor.location}</span>
      </div>
      <div className="d-flex justify-content-start align-items-center">
        <FontAwesomeIcon icon={faCoins} className="me-2" />
        <span className="package-name">{doctor.consultations}</span>
      </div>
    </div>
    <div className="card-footer d-flex justify-content-center">
      <button className="btn btn-primary">Dating</button>
    </div>
  </div>
);

const SlideMember = () => {
  const [doctors, setDoctors] = useState([]);
  const [occupationOptions, setOccupationOptions] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Retrieve token from local storage
  const currentUser = localStorage.getItem('user');
  const user = currentUser ? JSON.parse(currentUser) : null;
  const userToken = user ? user.token : '';

  useEffect(() => {
    if (!userToken) {
      setLoading(false);
      setIsAuthenticated(false);
      return;
    }
    
    setIsAuthenticated(true);
  
    const fetchOptions = async () => {
      try {
        const occupationResponse = await fetch('/data/occupationOptions.json');
        const occupationData = await occupationResponse.json();
        setOccupationOptions(occupationData);
  
        const nationalityResponse = await fetch('/data/nationalityOptions.json');
        const nationalityData = await nationalityResponse.json();
        setNationalityOptions(nationalityData);
  
        setOptionsLoaded(true); // Mark options as loaded
      } catch (error) {
        console.error('Error fetching occupation or nationality options:', error);
      }
    };
  
    fetchOptions();
  }, [userToken]); // Add 'userToken' to the dependency array
  

  useEffect(() => {
    if (optionsLoaded && isAuthenticated) {
      fetch('https://py-maracar.afaa.website/matching/api_env_2/user', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,  // Include Bearer token in the request
        },
      })
        .then(response => response.json())
        .then(data => {
          // Transform the API data to match the desired structure
          const transformedDoctors = data.map(user => {
            const occupationOption = occupationOptions.find(
              option => option.id === user.profile_data[0].occupation
            );
            const nationalityOption = nationalityOptions.find(
              option => option.id === user.profile_data[0].nationality
            );
            return {
              name: `${user.profile_data[0].name}`,
              speciality: occupationOption ? occupationOption.label : 'Loading...',
              location: nationalityOption ? nationalityOption.label : 'Loading...',
              consultations: user.user_packages[0].package,
              image: user.profile_data[0].profile_image || 'https://afaa.website/s/3d0891.svg',
            };
          });
          setDoctors(transformedDoctors);
          setLoading(false); // Set loading to false when data is ready
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false); // Also stop loading in case of error
        });
    }
  }, [optionsLoaded, isAuthenticated, userToken, occupationOptions, nationalityOptions]);

  if (!isAuthenticated) {
    return (
      <Swiper
        spaceBetween={30}
        slidesPerView={4} // Set slidesPerView to 4 for Skeleton display
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        navigation
        loop={false}  // Loop is disabled since we are using static skeletons
        modules={[Navigation, Pagination, Autoplay]} 
      >
        {[...Array(4)].map((_, index) => (
          <SwiperSlide key={index}>
            <Skeleton width="100%" height="300px" />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <ProgressSpinner />
      </div>
    );
  }

  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={doctors.length < 4 ? doctors.length : 4}  // Adjust slidesPerView based on number of slides
      autoplay={{ delay: 3000 }}
      pagination={{ clickable: true }}
      navigation
      loop={doctors.length >= 4}  // Enable loop only if there are enough slides
      modules={[Navigation, Pagination, Autoplay]} 
    >
      {doctors.map((doctor, index) => (
        <SwiperSlide key={index}>
          <DoctorCard doctor={doctor} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SlideMember;
