///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/api/api.js

import axios from 'axios';
import { API_URLS } from './apiConfig';

export const fetchProfile = async (userId, token) => {
    try {
        const response = await axios.get(API_URLS.FETCH_PROFILE, {
            params: { user_ID: userId },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch profile data');
    }
};

export const updateProfile = async (userId, token, updatedProfile) => {
    try {
        const response = await axios.post(API_URLS.EDIT_PROFILE, updatedProfile, {
            params: { user_ID: userId },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to update profile');
    }
};
