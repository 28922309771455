import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import '../style/Package.scss';

const Package = () => {
  const classes = [
    { name: 'Millionaire Class', income: '1 - 9 million USD' },
    { name: 'Decamillionaire Class', income: '10 - 49 million USD' },
    { name: 'Decamillionaire Class (Gold)', income: '50 - 99 million USD' },
    { name: 'Centimillionaire Class', income: '100 - 499 million USD' },
    { name: 'Centimillionaire Class (Gold)', income: '500 - 999 million USD' },
    { name: 'Billionaire Class', income: '1,000 - 4,999 million USD' },
    { name: 'Billionaire Class (Gold)', income: '5,000 - 9,999 million USD' },
    { name: 'Decabillionaire Class', income: '10,000 - 49,999 million USD' },
    { name: 'Decabillionaire Class (Gold)', income: '50,000 - 99,999 million USD' },
    { name: 'Centibillionaire Class', income: 'From 100,000 million USD' },
  ];

  const navigate = useNavigate();

  const handleRowClick = (selectedClass) => {
    navigate('/register', { state: { selectedClass, step: 2 } });
  };

  return (
    <div className="container mt-4">
      <table className="table table-bordered table-hover wealth-class-table table-package">
        <thead className="table-light">
          <tr>
            <th scope="col">Class</th>
            <th scope="col">Income</th>
          </tr>
        </thead>
        <tbody>
          {classes.map((item, index) => (
            <tr key={index} onClick={() => handleRowClick(item)}>
              <td>{item.name}</td>
              <td>{item.income}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Package;
