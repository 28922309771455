// /var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/Step1IncomeClass.js

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from 'primereact/card';
import { Container, Row, Col } from 'react-bootstrap';
import '../style/Registerstep1.scss'; // Import the styles

const Step1IncomeClass = ({ onNext }) => {
  const [selectedClass, setSelectedClass] = useState(null);
  const [incomeClasses, setIncomeClasses] = useState([]);

  useEffect(() => {
    // Fetch data from the JSON file
    const fetchIncomeClasses = async () => {
      try {
        const response = await fetch('/data/incomeClasses.json');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setIncomeClasses(data);
      } catch (error) {
        console.error("Failed to fetch income classes:", error);
      }
    };

    fetchIncomeClasses();
  }, []);

  const handleClassSelect = (incomeClass) => {
    console.log(`Income class selected: ${JSON.stringify(incomeClass)}`);
    setSelectedClass(incomeClass);
    onNext(incomeClass);
  };


  return (
    <Container className="income-class-selection mt-5">
      <Row className="justify-content-center">
        {incomeClasses.map((incomeClass) => (
          <Col key={incomeClass.id} md={6} lg={4} className="mb-4">
            <Card
              className={`income-card ${selectedClass && selectedClass.id === incomeClass.id ? 'selected' : ''}`}
              onClick={() => handleClassSelect(incomeClass)}
            >
              <h3>{incomeClass.name}</h3>
              <p>Income: {incomeClass.range}</p>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Step1IncomeClass;
