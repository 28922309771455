import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import '../style/Registerstep2.scss'; // Import your custom styles
import { classNames } from 'primereact/utils'; // Utility for managing classNames

const Step2PersonalInfo = ({ onNext, onBack, formData, handleChange }) => {
  const [genderOptions, setGenderOptions] = useState([]);
  const [matchingAgeOptions, setMatchingAgeOptions] = useState([]);
  const [occupationOptions, setOccupationOptions] = useState([]);
  const [carBrandOptions, setCarBrandOptions] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [lookingForOptions, setLookingForOptions] = useState([]);

  useEffect(() => {
    const fetchData = async (url, setOptions) => {
      try {
        const response = await fetch(url, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          const formattedData = data.map(option => ({
            label: option.label,
            value: option.id.toString() // Ensure the id is treated as a string
          })).sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically
          setOptions(formattedData);
        } else {
          const text = await response.text(); // Capture the response text for debugging
          throw new Error(`Received non-JSON response: ${text}`);
        }
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
      }
    };

    fetchData('/data/genderOptions.json', setGenderOptions);
    fetchData('/data/matchingAgeOptions.json', setMatchingAgeOptions);
    fetchData('/data/occupationOptions.json', setOccupationOptions);
    fetchData('/data/carBrandOptions.json', setCarBrandOptions);
    fetchData('/data/nationalityOptions.json', setNationalityOptions); // Added sorting
    fetchData('/data/lookingForOptions.json', setLookingForOptions);
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    if (type === 'radio') {
      handleChange({ target: { name, value } });
    } else if (name === 'telephone') {
      const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      handleChange({ target: { name, value: numericValue } });
    } else if (name === 'email') {
      const emailValue = value.replace(/[^a-zA-Z0-9@._-]/g, ''); // Allow only valid email characters
      handleChange({ target: { name, value: emailValue } });
    } else {
      handleChange(e);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleNext = () => {
    console.log("Next button clicked");
    onNext(formData);
  };

  const handleNumberInputClick = (e) => {
    handleChange({ target: { name: e.target.name, value: '' } });
  };

  const handleNumberInputChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (e.target.name === 'number_of_cars' && parseInt(value) > 99) {
      handleChange({ target: { name: e.target.name, value: '99' } });
    } else {
      handleChange({ target: { name: e.target.name, value } });
    }
  };

  // Convert the birthday string back to a Date object for Calendar component
  const getCalendarDate = (dateString) => {
    if (!dateString) return null;
    const [year, month, day] = dateString.split('-');
    return new Date(year, month - 1, day);
  };

  const handleBirthdayChange = (e) => {
    const selectedDate = e.value;
    if (!selectedDate) {
      handleChange({ target: { name: 'birthday', value: '' } });
      return;
    }

    const formattedDate = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
    handleChange({ target: { name: 'birthday', value: formattedDate } });
  };

  return (
    <Card className="form-container">
      <Container>
        <div className="form-title">Registration Form</div>
        <form>
          <Row className="mb-2r">
            <Col md={6}>
              <div className="p-float-label">
                <InputText id="first_name" name="first_name" value={formData.first_name} onChange={handleInputChange} />
                <label htmlFor="first_name">First Name</label>
              </div>
            </Col>
            <Col md={6}>
              <div className="p-float-label">
                <InputText id="surname" name="surname" value={formData.surname} onChange={handleInputChange} />
                <label htmlFor="surname">Last Name</label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2r">
            <Col md={6}>
              <div className="p-float-label">
                <InputText id="social_media" name="social_media" value={formData.social_media} onChange={handleInputChange} />
                <label htmlFor="social_media">Social Media Link facebook / twitter / IG / Tiktok / Youtube</label>
              </div>
            </Col>
            <Col md={6}>
              <div className="p-float-label">
                <InputText id="telephone" name="telephone" value={formData.telephone} onChange={handleInputChange} />
                <label htmlFor="telephone">Telephone</label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2r">
            <Col md={6}>
              <div className="p-float-label">
                <InputText id="email" name="email" value={formData.email} onChange={handleInputChange} />
                <label htmlFor="email">Email</label>
              </div>
            </Col>
            <Col md={6}>
              <div className="p-float-label p-inputgroup">
                <InputText id="password" name="password" type={passwordVisible ? 'text' : 'password'} value={formData.password} onChange={handleInputChange} />
                <label htmlFor="password">Password</label>
                <span className="p-inputgroup-addon" onClick={togglePasswordVisibility}>
                  <i className={classNames('pi', passwordVisible ? 'pi-eye-slash' : 'pi-eye')}></i>
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mb-2r">
            <Col md={6}>
              <div className="p-float-label">
                <Dropdown id="gender" name="gender" options={genderOptions} value={formData.gender} onChange={handleInputChange} placeholder="Select Gender" />
                <label htmlFor="gender">Gender</label>
              </div>
            </Col>
            <Col md={6}>
              <div className="p-float-label">
                <Dropdown id="matching_gender" name="matching_gender" options={genderOptions} value={formData.matching_gender} onChange={handleInputChange} placeholder="Select Matching Gender" />
                <label htmlFor="matching_gender">Matching Gender</label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2r">
            <Col md={6}>
              <div className="p-float-label">
                <Dropdown id="nationality" name="nationality" options={nationalityOptions} value={formData.nationality} onChange={handleInputChange} placeholder="Select Nationality" />
                <label htmlFor="nationality">Your Nationality</label>
              </div>
            </Col>
            <Col md={6}>
              <div className="p-float-label">
                <Dropdown id="matching_nationality" name="matching_nationality" options={nationalityOptions} value={formData.matching_nationality} onChange={handleInputChange} placeholder="Select Matching Nationality" />
                <label htmlFor="matching_nationality">Matching Nationality</label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2r">
            <Col md={6}>
              <div className="p-float-label">
                <label htmlFor="birthday">Your Birthday</label>
                <Calendar id="birthday" name="birthday" value={getCalendarDate(formData.birthday)} onChange={handleBirthdayChange} dateFormat="dd/mm/yy" showIcon readOnlyInput />
              </div>
            </Col>
            <Col md={6}>
              <div className="p-float-label">
                <Dropdown id="matching_age" name="matching_age" options={matchingAgeOptions} value={formData.matching_age} onChange={handleInputChange} placeholder="Select Matching Age" />
                <label htmlFor="matching_age">Matching Age</label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2r">
            <Col md={6}>
              <div className="p-float-label">
                <Dropdown id="occupation" name="occupation" options={occupationOptions} value={formData.occupation} onChange={handleInputChange} placeholder="Select Occupation" />
                <label htmlFor="occupation">Occupation</label>
              </div>
            </Col>
            <Col md={6}>
              <div className="p-float-label">
                <Dropdown id="matching_occupation" name="matching_occupation" options={occupationOptions} value={formData.matching_occupation} onChange={handleInputChange} placeholder="Select Matching Occupation" />
                <label htmlFor="matching_occupation">Matching Occupation</label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2r">
            <Col md={4}>
              <div>
                <label className="radio-group-label">Do you have a private airplane?</label>
                <div className="custom-radio-group">
                  <input type="radio" id="private_airplane_yes" name="private_airplane" value="true" checked={formData.private_airplane === 'true'} onChange={handleInputChange} />
                  <label htmlFor="private_airplane_yes">Yes</label>
                  <input type="radio" id="private_airplane_no" name="private_airplane" value="false" checked={formData.private_airplane === 'false'} onChange={handleInputChange} />
                  <label htmlFor="private_airplane_no">No</label>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <label className="radio-group-label">Do you have a private yacht?</label>
                <div className="custom-radio-group">
                  <input type="radio" id="private_yacht_yes" name="private_yacht" value="true" checked={formData.private_yacht === 'true'} onChange={handleInputChange} />
                  <label htmlFor="private_yacht_yes">Yes</label>
                  <input type="radio" id="private_yacht_no" name="private_yacht" value="false" checked={formData.private_yacht === 'false'} onChange={handleInputChange} />
                  <label htmlFor="private_yacht_no">No</label>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <label className="radio-group-label">Do you have a private car?</label>
                <div className="custom-radio-group">
                  <input type="radio" id="private_car_yes" name="private_car" value="true" checked={formData.private_car === 'true'} onChange={handleInputChange} />
                  <label htmlFor="private_car_yes">Yes</label>
                  <input type="radio" id="private_car_no" name="private_car" value="false" checked={formData.private_car === 'false'} onChange={handleInputChange} />
                  <label htmlFor="private_car_no">No</label>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-2r">
            <Col md={6}>
              <div className="p-float-label">
                <Dropdown id="car_brand" name="car_brand" options={carBrandOptions} value={formData.car_brand} onChange={handleInputChange} placeholder="Select Car Brand" />
                <label htmlFor="car_brand">Car Brand</label>
              </div>
            </Col>
            <Col md={6}>
              <div className="p-float-label">
                <InputText id="number_of_cars" name="number_of_cars" value={formData.number_of_cars} onClick={handleNumberInputClick} onChange={handleNumberInputChange} />
                <label htmlFor="number_of_cars">Number of Cars</label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2r">
            <Col md={6}>
              <div className="p-float-label">
                <InputText id="income" name="income" value={formData.income} onClick={handleNumberInputClick} onChange={handleNumberInputChange} />
                <label htmlFor="income">Income (USD)</label>
              </div>
            </Col>
            <Col md={6}>
              <div className="p-float-label">
                <InputText id="net_worth" name="net_worth" value={formData.net_worth} onClick={handleNumberInputClick} onChange={handleNumberInputChange} />
                <label htmlFor="net_worth">Total Net Worth (USD)</label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2r">
            <Col md={12}>
            
            <div>
              <label className="radio-group-label">What are you looking for?</label>
              <div className="custom-radio-group">
                  {lookingForOptions.map((option, index) => (
                    <React.Fragment key={index}>
                      <input
                        type="radio"
                        id={`looking_for_${index}`}
                        name="looking_for"
                        value={index}
                        checked={formData.looking_for === index.toString()}
                        onChange={handleInputChange}
                      />
                      <label htmlFor={`looking_for_${index}`}>{option.label}</label>
                    </React.Fragment>
                  ))}
                </div>
            </div>

            </Col>
          </Row>
          <div className="text-center">
            <Button label="Back" onClick={onBack} className="mr-2 submit-button" />
            <Button label="Next" onClick={handleNext} className="submit-button" />
          </div>
        </form>
      </Container>
    </Card>
  );
};

export default Step2PersonalInfo;
