///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/page/FindMatch.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SlideMember from '../component/SlideMember';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'bootstrap/dist/css/bootstrap.min.css';

const FindMatch = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const gender = searchParams.get('gender');
    const seeking = searchParams.get('seeking');
    const ageFrom = searchParams.get('ageFrom');
    const ageTo = searchParams.get('ageTo');

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Mock delay to simulate data fetching
        const timer = setTimeout(() => {
            setLoading(false);  // Set loading to false once data is ready
        }, 1000);

        return () => clearTimeout(timer);  // Cleanup the timer
    }, []);

    return (
        <div className="find-match-container">
            <h2>Find Your Match</h2>
            <p>Results for: {gender} looking for {seeking}, aged {ageFrom} to {ageTo}</p>

            <div className="container mt-4 mb-5">
                <div className="text-center mb-4">

                    {loading ? (
                        <div className="spinner-container">
                            <ProgressSpinner />
                        </div>
                    ) : (
                        <SlideMember gender={gender} seeking={seeking} ageFrom={ageFrom} ageTo={ageTo} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FindMatch;
