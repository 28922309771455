// /var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/Navbar.js
import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS

import '../style/Navbar.scss';

const AppNavbar = () => {
  const handleLogout = async () => {
    try {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const user = JSON.parse(storedUser);
        await fetch('https://py-maracar.afaa.website/logout/api/checkout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`,  // Add the token to the Authorization header
          },
        });
        localStorage.clear();
        window.location.href = '/login';
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const isLoggedIn = !!localStorage.getItem('user');

  return (
    <Navbar className="custom-navbar" expand="lg">
      <Container>
        <Navbar.Brand href="/">VIP-MATCHING</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {isLoggedIn ? (
              <>
                <Nav.Link href="/profile">
                  <i className="fas fa-user"></i> Profile
                </Nav.Link>
                <Nav.Link onClick={handleLogout} className="btn btn-primary text-white">
                  <i className="fas fa-sign-out-alt"></i> Logout
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link href="/register">
                  <i className="fas fa-user"></i> REGISTER
                </Nav.Link>
                <Nav.Link href="/login" className="btn btn-primary text-white">
                  <i className="fas fa-lock"></i> LOGIN
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
