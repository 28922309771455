///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/utils/getNextMatch.js


// Remove the mock data import
// import mockData from '../data/MockDataUserMatching.js';
// Function to fetch data from the API
const fetchDataFromAPI = async (userToken) => {
  try {
    const response = await fetch('https://py-maracar.afaa.website/matching/api_env_2/user', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,  // Include Bearer token in the request
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data from API');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

// Modify getNextMatch to use the API data
const getNextMatch = async (currentUser, viewedMatches = new Set()) => {
  console.log('Current User:', currentUser); // Logging current user
  console.log('Viewed Matches:', viewedMatches); // Logging viewed matches

  // Ensure the user token is available
  const user = currentUser ? JSON.parse(localStorage.getItem('user')) : null;
  if (!user || !user.token) {
    console.error('User token is missing or invalid');
    return [];
  }

  // Fetch data from the API
  const apiData = await fetchDataFromAPI(user.token);
  if (!apiData) return [];

  console.log('API Data:', apiData); // Log the raw API data

  // Flatten the hierarchical structure to get a single array of user profiles
  const flattenedData = apiData.flatMap(user => {
    console.log('User Data:', user); // Log each user object to inspect its structure
    return user.profile_data.map(profile => ({
      ...profile,
      package: user.user_packages?.[0]?.package || 'Basic' // Check if user_packages exists
    }));
  });

  // Simulating filtered data based on currentUser's preferences
  const filteredData = flattenedData.filter(user => {
    const matchesGender = user.gender === currentUser.matching_gender;
    const matchesNationality = currentUser.matching_nationality === "Any" || user.nationality === currentUser.matching_nationality;
    const matchesOccupation = currentUser.matching_occupation === user.matching_occupation;
    const matchesIncome = parseFloat(user.income) >= currentUser.income; // Ensure income is parsed correctly
    const matchesNetWorth = parseFloat(user.net_worth) >= currentUser.net_worth;
    const matchesCarBrand = currentUser.car_brand === user.car_brand;
    const matchesIncomeClass = currentUser.income_class === user.income_class_id;
    const notViewed = !viewedMatches.has(user.user_id);

    console.log(`User ${user.user_id} - matchesGender: ${matchesGender}, matchesNationality: ${matchesNationality}, matchesOccupation: ${matchesOccupation}, matchesIncome: ${matchesIncome}, matchesNetWorth: ${matchesNetWorth}, matchesCarBrand: ${matchesCarBrand}, matchesIncomeClass: ${matchesIncomeClass}, notViewed: ${notViewed}`);

    return matchesGender && matchesNationality && matchesOccupation && matchesIncome && matchesNetWorth && matchesCarBrand && matchesIncomeClass && notViewed;
  });

  console.log('Filtered Data:', filteredData); // Displaying the filtered data for debugging

  // Sorting matches based on the priority of packages
  filteredData.sort((a, b) => {
    const packagePriority = {
      'Basic': 1,
      'Basic+': 2,
      'Premium': 3,
      'Premium+': 4,
      'Exclusive': 5,
      'Luxurious': 6,
      'Full Service': 7,
      'Ultra Luxurious': 8,
      'Top Tier': 9
    };
    return packagePriority[b.package] - packagePriority[a.package];
  });

  return filteredData;
};

export default getNextMatch;
