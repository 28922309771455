///var/www/website2024/192.testproject.work/website2024/s-302-dating/website/my-app/src/component/ToastNotification.js

import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Toast } from 'primereact/toast';

const ToastNotification = forwardRef((props, ref) => {
  const toast = useRef(null);

  useImperativeHandle(ref, () => ({
    showSuccess: (message) => {
      toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
    },
    showError: (message) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
    }
  }));

  return <Toast ref={toast} />;
});

export default ToastNotification;
